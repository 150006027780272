var xhr = new XMLHttpRequest();
var url, apiToken;
if ( process.env.REACT_APP_NODE_ENV === 'production' ) {
    apiToken = "34caef0332595d13aa8148b02bea442f62bb1fa6";
    url = "https://watemplates.bimyou.es/api/v1/";
} else {
    apiToken = "ade6eb971138ed3287981e3b2799349ca03f53f2";
    url = "https://watemplatespre.bimyou.es/api/v1/";
}

export const getList = () => new Promise((resolve, reject) => {
    xhr.open('GET', `${url}list`, false);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", `Bearer ${apiToken}`);
    xhr.send(null);

    if ( xhr.status !== 200 ) {
        return reject(JSON.parse(xhr.response));
    } else {
        return resolve(JSON.parse(xhr.response));
    }
});


export const countryObject = () => new Promise((resolve, reject) => {
    xhr.open('GET', `${url}templates/countries`, false);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", `Bearer ${apiToken}`);
    xhr.send(null);

    if ( xhr.status !== 200 ) {
        return reject(JSON.parse(xhr.response));
    } else {
        return resolve(JSON.parse(xhr.response));
    }
});