var xhr = new XMLHttpRequest();
var url, apiToken;
if ( process.env.REACT_APP_NODE_ENV === 'production' ) {
    apiToken = "34caef0332595d13aa8148b02bea442f62bb1fa6";
    url = "https://watemplates.bimyou.es/api/v1/"; 
} else {
    apiToken = "ade6eb971138ed3287981e3b2799349ca03f53f2";
    url = "https://watemplatespre.bimyou.es/api/v1/";
}

export const editElement = (data) => new Promise((resolve, reject) => {
    xhr.open('PUT', `${url}edit`, false);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(JSON.stringify(data));

    if ( xhr.status !== 200 ) {
        return reject(JSON.parse(xhr.response));
    } else {
        return resolve(JSON.parse(xhr.response));
    }
});

export const createElement = (data) => new Promise((resolve, reject) => {
    xhr.open('POST', `${url}templates`, false);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", `Bearer ${apiToken}`);
    xhr.send(JSON.stringify(data));
    // return resolve({ message : 'OK'});

    if ( xhr.status !== 200 ) {
        return reject(JSON.parse(xhr.response));
    } else {
        return resolve(JSON.parse(xhr.response));
    }
});