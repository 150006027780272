import React, { Component } from 'react';
import { Fab, TextField, Divider, IconButton } from '@material-ui/core';
import { Redirect } from "react-router-dom";
import { getStructureCookie, emptyObject, deleteCookie } from '../infrastructure/helper';
import { getList } from '../infrastructure/apps';
import LastPage from '@material-ui/icons/LastPage';
import FirstPage from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...getStructureCookie(),
            viewComplete : [],
            filterArray : [],
            searchString : '',
            searchArray: '',
            view : [],
            actualPage : '',
            firstPage : '',
            lastPage : '',
            maxElements : ''
        };
        this.redirectToLogin = this.redirectToLogin.bind(this);
        this.selectorApp = this.selectorApp.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        if ( this.props.location.state) {
            const { user, passwd } = this.props.location.state;
            if ( user && passwd ) {
                getList().then((res) => {
                    var arraySplit = [];
                    var b = JSON.parse(JSON.stringify(res));
                    while(res.length) {
                        arraySplit.push(res.splice(0,10));
                    }
                    this.setState({ viewComplete : b , app: arraySplit, searchArray : arraySplit, actualPage : 0, firstPage : 0, lastPage : arraySplit.length - 1 });
                    this.pagination(0);
                  })
                  .catch((err) => {
                    console.log('[v] Error LOG : ',err );
                    this.redirectToLogin();
                })
            } else {
                this.redirectToLogin();
            }
        } else {
            this.redirectToLogin();
        }
    }

    redirectToLogin() {
        deleteCookie();
        this.setState({ isValid: false })
    }
    
    selectorApp(e) {
        this.setState({ selectApp : e.target.value });
    }

    retrySelect(e) {
        e.preventDefault();
        this.setState({ status : 'select' });
    }

    editElement(e, el) {
        e.preventDefault();
        this.setState({ edit : el })
    }

    createElement = (e) => {
        e.preventDefault();
        this.setState({ create: true })
    }

    filterTemplate = (e) => {
        e.preventDefault();
        const { viewComplete } = this.state;

        var string = e.target.value;
        var arraySplit = [];
        var filter = viewComplete.filter(({ zendeskSubdomain }) => zendeskSubdomain.toLowerCase().includes(string.toLowerCase()));
        while(filter.length) {
            arraySplit.push(filter.splice(0,10));
        }
        this.setState({ filterArray : arraySplit, searchString : string, actualPage : 0, firstPage : 0, lastPage : arraySplit.length - 1 });
        this.pagination(0,true,arraySplit);
    }

    pagination = (value,filter,arraySplit) => {
        const { searchString } = this.state;

        if ( searchString || filter ) {
            if ( (arraySplit) ? arraySplit.length > 0 : this.state.filterArray.length > 0 ) {
                this.setState({ searchArray : (arraySplit) ? arraySplit[value] : this.state.filterArray[value], actualPage : value });
            } else {
                this.setState({ searchArray : [] });
            }
        } else {
            this.setState({ view : this.state.app[value], actualPage : value });
        }
    }

    previousPage = (e) => {
        e.preventDefault();
        const { actualPage } = this.state;
        if ( actualPage !== 0 ) {
            this.pagination( actualPage - 1 );
        }
    }

    nextPage = (e) => {
        e.preventDefault();
        const { actualPage, lastPage } = this.state;
        if ( actualPage !== lastPage ) {
            this.pagination( actualPage + 1 );
        }
    }

    firstPage = (e) => {
        e.preventDefault();
        this.pagination(0);
    }

    lastPage = (e) => {
        e.preventDefault();
        const { lastPage } = this.state;
        this.pagination( lastPage );
    }

    render() {
        const { app, edit, isValid, create, searchString, searchArray, view, actualPage, firstPage, lastPage } = this.state;
        if (!isValid) {
            return <Redirect to={{
                pathname: '/'
            }} />;
        } else if (edit) {
            return <Redirect to={{
                pathname: '/app/edit',
                state: { ...this.props.location.state, edit : edit}
            }} />; 
        } else if (create) {
            return <Redirect to={{
                pathname: '/app/create',
                state: { ...this.props.location.state, edit : emptyObject()}
            }} />; 
        }
        return (
            <React.Fragment>
            <div>
                <div className="a-header">
                    <img alt="bimyou-logo" src="https://www.the-eawards.com/madrid16/sources/0fb9h/items/cache/84c42b9986b8cecdea81ed6abb66c108_L.jpg"></img>
                    <Fab className="a-logOut" aria-label="logOut" onClick={this.redirectToLogin}>
                        <img alt="LogOut" src={process.env.PUBLIC_URL + '/power.svg'} /> 
                    </Fab>
                </div>
                {
                    app && app.length > 0 && (
                        <div className="a-filter">
                            <TextField
                                style={{ margin: 8, fontSize: 24 }}
                                placeholder={'Filter by Subdomain'}
                                margin="normal"
                                onChange={this.filterTemplate}
                            />
                        </div>
                    )
                }
                <div className="list-content">
                    { app && 
                        app.length > 0 ? (
                            searchString ? (
                            <React.Fragment>
                                {
                                    searchArray.length > 0 ? (
                                        searchArray.map((el, i) => {
                                            return (
                                                <React.Fragment key={i}>
                                                <div className="list-select" onClick={(e) => { this.editElement(e, el) }}>
                                                    <span>{el.templateName}</span>
                                                    <span>{el.zendeskSubdomain}</span>
                                                    <img alt="Warning" src={process.env.PUBLIC_URL + '/edit.svg'} /> 
                                                </div>
                                                <Divider></Divider>
                                                </React.Fragment>
                                            )
                                        })
                                    ) : (
                                        <span className="no-Results">No results found.</span>
                                    )
                                }
                            </React.Fragment>
                            ) : (
                            <React.Fragment>
                                {
                                    view.map((el, i) => {
                                        return (
                                            <React.Fragment key={i}>
                                            <div className="list-select" onClick={(e) => { this.editElement(e, el) }}>
                                                <span>{el.templateName}</span>
                                                <span>{el.zendeskSubdomain}</span>
                                                <img alt="Edit" src={process.env.PUBLIC_URL + '/edit.svg'} /> 
                                            </div>
                                            <Divider></Divider>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </React.Fragment>
                            )
                        ) : 
                        (
                            <span className="no-Templates">This account not have templates yet.</span>
                        )
                    }
                    {
                        lastPage > 0 && (
                            <div className="a-pagination">
                                <IconButton disabled={ actualPage === firstPage } onClick={this.firstPage} aria-label="First Page">
                                    <FirstPage fontSize="inherit" />
                                </IconButton>
                                <IconButton disabled={ actualPage === firstPage } onClick={this.previousPage} aria-label="Previous Page">
                                    <KeyboardArrowLeft fontSize="inherit" />
                                </IconButton>
                                <span> {actualPage+1} of {lastPage+1} </span>
                                <IconButton disabled={ actualPage === lastPage } onClick={this.nextPage} aria-label="Next Page">
                                    <KeyboardArrowRight fontSize="inherit" />
                                </IconButton>
                                <IconButton disabled={ actualPage === lastPage } onClick={this.lastPage} aria-label="Last Page">
                                    <LastPage fontSize="inherit" />
                                </IconButton>
                            </div>       
                        )
                    }
                </div>
            <Fab className="a-create" aria-label="create" onClick={this.createElement}>
                <img alt="create" src={process.env.PUBLIC_URL + '/add.svg'} /> 
            </Fab>
            </div>
            </React.Fragment>
        );
    }
}


export default App;
