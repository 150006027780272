import React, { Component } from 'react';
import { LoginUser } from '../infrastructure/login';
import { getStructure, setCookie } from '../infrastructure/helper';
import { Redirect } from "react-router-dom";
import Icon from '@mdi/react';
import { mdiAccount, mdiLock, mdiAlertCircle } from '@mdi/js';

class Login extends Component {
  constructor(props) {
    super(props);
    var str = getStructure();
    this.state = {
      ...str
    };
    this.user = '';
    this.passwd = '';
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    const { user, passwd } = this;
    if (user && passwd) {
      this.setState({ isSending: true });
      LoginUser(user, passwd).then((res) => {
        this.setState({ isSending: false, isLoading: true });
      })
      .catch((err) => {
        this.setState({ isSending: false, error: err.message});
      })
    } else {
      this.setState({ error: 'There are empty fields' });
    }
  }

  redirect() {
    return <Redirect to={{
      pathname: '/app',
      state: { ...this.state, user : this.user, passwd : this.passwd}
    }} />;
  }

  renderHTML() {
    const { isSending, error} = this.state;
    return (
      <div className="l-back">
        <span className="l-title">TEMPLATE ADMIN</span>
        <div className="l-screen"></div>
        <div className={`l-login display-center ${error ? 'shake' : ''}`}>
          {error && (<div className="msg error display-center"><Icon path={mdiAlertCircle} size={1}/> {error} </div>)}
          <form>
            <input
              onChange={event => this.user = event.target.value }
              type="text"
              autoComplete="username"
              placeholder="Username"
              required
            />
            <span className="l-icons user"><Icon path={mdiAccount} size={'25px'}/></span>
            <input
              onChange={event => this.passwd = event.target.value }
              type="password"
              autoComplete="current-password"
              placeholder="Password"
              required
            />
            <span className="l-icons passwd"><Icon path={mdiLock} size={'25px'}/></span>
            <input className="btn" type="submit" onClick={this.handleSubmit} value="Submit" disabled={isSending} />
          </form>
        </div>
      </div>
    )
  }

  render() {
    console.log('[v] SET COOKIE FROM LOGIN', );
    setCookie(this.state);
    const {isLoading } = this.state;
    if (isLoading) {
      return this.redirect();
    } else {
      return this.renderHTML();
    }
  }
}

export default Login;