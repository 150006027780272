export const getStructure = () => {
    var str = {
        error: '',
        isSending: false,
        isValid: true,
        isVisible: false,
        app : [],
        selectApp : '',
        status : 'select',
        edit: false,
        cancel: false,
        create: false
    };
    return str;
};

export const emptyObject = () => {
    return {
        "activo": false,
        "zendeskSubdomain": "",
        "namespace": "",
        "templateName": "Default",
        "language": "es",
        "content": "",
        "country": "",
        "approved" : false,
        "smoochAppId" : "",
        "varNames": []
    }
}

export const langObject = () => {
    return ['es','ca','en','fr','pt','it','ES-es','pt-PT', 'es_MX', 'es_ES', 'es_AR'];
}

export const getStructureCookie = (isCorrect) => {
    if ( isCorrect ) {
        return isCorrect;
    } else {
        var input = document.cookie.match('tpBim');
        if ( input ) {
            var result = input.input;
            var acc = result.split('tpBim=')[1];
            acc && (result = acc.split('path=/')[0]);
            return JSON.parse((result.replace(';', '')));
        }
        return null;
    }
}

export const setCookie = (state) => {
    var date = new Date();
    date.setTime(date.getTime()+(1*24*60*60*1000));
    document.cookie = ['tpBim=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/'].join('');
    var cookie = `tpBim=${JSON.stringify(state)}; expires=${date.toGMTString()}; path=/`;
    document.cookie = cookie;
}

export const deleteCookie = () => {
    document.cookie = ['tpBim=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/'].join('');
}
