import React, { Component } from 'react';
import { TextField, Button, FormControl, Select, MenuItem, Divider, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Switch, Fab } from '@material-ui/core';
import { Redirect } from "react-router-dom";
import { deleteCookie, langObject } from '../infrastructure/helper';
import { createElement } from '../infrastructure/create';
import { countryObject } from '../infrastructure/apps';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class Create extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.location.state,
            notCreating: true,
            create : false,
            lang : langObject(),
            country : null
        };
        this.redirectToLogin = this.redirectToLogin.bind(this);
        this.cancel = this.cancel.bind(this);
        this.dataUpdate = this.dataUpdate.bind(this);
        this.addVariable = this.addVariable.bind(this);
    }

    componentDidMount() {
        if ( this.state.edit ) {
            this.changesUpdate = JSON.parse(JSON.stringify(this.state.edit));
            countryObject().then((res) => {
                this.setState({ country: res });
            })
              .catch((err) => {
            })
        } else {
            this.redirectToLogin();
        }
    }


    redirectToLogin() {
        deleteCookie();
        this.setState({ isValid: false })
    }

    cancel(e) {
        e.preventDefault();
        this.setState({ cancel: true })
    }

    addVariable(e) {
        e.preventDefault();
        this.changesUpdate.varNames.push({ name : '', description: '' });
        this.dataUpdate();
    }

    dataUpdate(e, type, i) {
        switch ( type ) {
            case "templateName" : this.changesUpdate.templateName = e.target.value; break;
            case "zendeskSubdomain" : this.changesUpdate.zendeskSubdomain = e.target.value; break;
            case "namespace" : this.changesUpdate.namespace = e.target.value; break;
            case "language" : this.changesUpdate.language = e.target.value; break;
            case "country" : this.changesUpdate.country = e.target.value; break;
            case "content" : this.changesUpdate.content = e.target.value; break;
            case "smoochAppId" : this.changesUpdate.smoochAppId = e.target.value; break;
            case "activo" : this.changesUpdate.activo = !this.changesUpdate.activo; break;
            case "approved" : this.changesUpdate.approved = !this.changesUpdate.approved; break;
            case "varNames" :
                switch ( i.mod ) {
                    case "name" : this.changesUpdate.varNames[i.key].name = e.target.value; break;
                    case "description": this.changesUpdate.varNames[i.key].description = e.target.value; break;
                }
            break;
        }
        var l = Object.values(this.changesUpdate);
        var isEmpty = true;
        for ( var y = 0; y < l.length; y++ ) {
            if ( l[y] === "" || l[y] === null ) {
                isEmpty = false;
            }
        }
        var c = false;
        // Compruebo si dentro de varNames hay alguna iteración con algún campo sin rellenar, si está sin rellenar dejo el botón en disabled
        for ( var x = 0; x < this.changesUpdate.varNames.length; x++ ) {
            if ( !this.changesUpdate.varNames[x].name || !this.changesUpdate.varNames[x].description ) {
                this.setState({ notCreating : true });
                c = true;
            }
        }
        // Si hay mínimo una iteración en varNames, está rellenado y todos los campos del objeto están completos muestro el botón
        if ( !c && isEmpty) {
            this.setState({ notCreating : false })
        } else {
            this.setState({ notCreating : true });
        }

    }

    create = (e) => {
        e.preventDefault();
        createElement(this.changesUpdate).then((res) => {
            this.setState({ create: true });
        })
        .catch((err) => {
            console.log('[v] Error LOG : ',err );
        })
    }

    deleteVariable = (e, key) => {
        e.preventDefault();
        this.changesUpdate.varNames.splice(key, 1);
        this.dataUpdate();
    }

    render() {
        const { isValid, cancel, notCreating, lang, country, create } = this.state;
        const { changesUpdate } = this;
        if (!isValid) {
            return <Redirect to={{
                pathname: '/'
            }} />;
        } else if ( cancel ) {
            return <Redirect to={{
                pathname: '/app',
                state: this.props.location.state
            }} />;
        } else if ( create ) {
            return <Redirect to={{
                pathname: '/app',
                state: { ...this.state, edit : changesUpdate }
            }} />;
        }
        return (
            <React.Fragment>
            <div>
                <div className="a-header">
                <img alt="bimyou-logo" src="https://www.the-eawards.com/madrid16/sources/0fb9h/items/cache/84c42b9986b8cecdea81ed6abb66c108_L.jpg"></img>
                    <Fab className="a-logOut" aria-label="logOut" onClick={this.redirectToLogin}>
                        <img alt="LogOut" src={process.env.PUBLIC_URL + '/power.svg'} /> 
                    </Fab>
                </div>
                <div className="list-content p-10">
                    { (changesUpdate && country) && 
                        <React.Fragment>
                            <div className="edit-header">
                                <TextField
                                    className="edit-h"
                                    style={{ margin: 8, fontSize: 24 }}
                                    defaultValue={changesUpdate.templateName}
                                    margin="normal"
                                    onChange={(e) => { this.dataUpdate(e, 'templateName')}}
                                />
                                <div className="edit-buttons">
                                <Button variant="contained" onClick={this.cancel}>
                                    CANCEL
                                </Button>
                                <Button variant="contained" onClick={this.create} disabled={notCreating}>
                                    CREATE
                                </Button>
                                </div>
                            </div>
                            <div className="edit-content">
                                {
                                    <React.Fragment>
                                        <div className="list-select list-edit">
                                            <span>Zendesk Subdomain</span>
                                            <TextField
                                                style={{ fontSize: 14 }}
                                                defaultValue={changesUpdate.zendeskSubdomain}
                                                margin="normal"
                                                onChange={(e) => { this.dataUpdate(e, 'zendeskSubdomain')}}
                                            />
                                        </div>
                                        <Divider></Divider>
                                        <div className="list-select list-edit list-sm">
                                            <span>Smooch APP ID</span>
                                            <TextField
                                                style={{ fontSize: 14 }}
                                                defaultValue={changesUpdate.smoochAppId}
                                                margin="normal"
                                                onChange={(e) => { this.dataUpdate(e, 'smoochAppId')}}
                                            />
                                        </div>
                                        <Divider></Divider>
                                        <div className="list-select list-edit list-n">
                                            <span>Namespace</span>
                                            <TextField
                                                style={{ fontSize: 14 }}
                                                defaultValue={changesUpdate.namespace}
                                                margin="normal"
                                                onChange={(e) => { this.dataUpdate(e, 'namespace')}}
                                            />
                                        </div>
                                        <Divider></Divider>
                                        <div className="list-select list-edit list-l">
                                            <span>Language</span>
                                            <FormControl>
                                            <Select
                                                value={changesUpdate.language}
                                                onChange={(e) => { this.dataUpdate(e, 'language')}}
                                                >
                                                    {
                                                       lang.map(function(i) {
                                                            return ( 
                                                                <MenuItem key={i} value={i}>{i}</MenuItem> 
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <Divider></Divider>
                                        <div className="list-select list-edit list-l">
                                            <span>Country</span>
                                            <FormControl>
                                            <Select
                                                value={changesUpdate.country}
                                                onChange={(e) => { this.dataUpdate(e, 'country')}}
                                                >
                                                    {
                                                       country.map(function(i) {
                                                            return ( 
                                                                <MenuItem key={i.id} value={i.id}>{i.name}</MenuItem> 
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <Divider></Divider>
                                        <div className="list-select list-edit list-c">
                                            <span>Content</span>
                                            <TextField
                                                style={{ fontSize: 14 }}
                                                defaultValue={changesUpdate.content}
                                                margin="normal"
                                                multiline
                                                onChange={(e) => { this.dataUpdate(e, 'content')}}
                                            />
                                        </div>
                                        <Divider></Divider>
                                        <div className="list-select list-edit list-expand">
                                        <ExpansionPanel>
                                            <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            >
                                            <span>Variables</span>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                {
                                                    changesUpdate.varNames.map((el, i) => {
                                                        return (
                                                            <React.Fragment key={i}>
                                                                <div className="list-select">
                                                                <TextField
                                                                    style={{ fontSize: 14 }}
                                                                    defaultValue={el.name}
                                                                    margin="normal"
                                                                    onChange={(e) => { this.dataUpdate(e, 'varNames', { key : i, mod : 'name'})}}
                                                                />
                                                                <TextField
                                                                    style={{ fontSize: 14 }}
                                                                    defaultValue={el.description}
                                                                    margin="normal"
                                                                    onChange={(e) => { this.dataUpdate(e, 'varNames', { key : i, mod : 'description'})}}
                                                                />
                                                                <img alt="Close"
                                                                onClick={(e) => { this.deleteVariable(e, i )}}
                                                                src={process.env.PUBLIC_URL + '/close.svg'} /> 
                                                                </div>
                                                                <Divider></Divider>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                                <div className="edit-buttons">
                                                    <Button variant="contained" onClick={this.addVariable}>
                                                        ADD
                                                    </Button>
                                                </div>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                        </div>
                                        <Divider></Divider>
                                        <div className="list-select list-edit list-a">
                                            <span>Active</span>
                                            <Switch
                                                checked={(changesUpdate.activo ? changesUpdate.activo : false)}
                                                onClick={(e) => { this.dataUpdate(e, 'activo')}}
                                            />
                                        </div>
                                        <Divider></Divider>
                                        <div className="list-select list-edit list-a">
                                            <span>Approved</span>
                                            <Switch
                                                checked={(changesUpdate.approved ? changesUpdate.approved : false)}
                                                onClick={(e) => { this.dataUpdate(e, 'approved')}}
                                            />
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        </React.Fragment>
                    }
                </div>
            </div>
            </React.Fragment>
        );
    }
}


export default Create;
