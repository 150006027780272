import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Login from './Login';
import App from './App';
import Edit from './Edit';
import Create from './Create';
import '../styles/App.scss';

const Root = () => (
	<BrowserRouter>
		<Route exact path="/" component={Login}/>
		<Route exact path="/app" component={App}/>
		<Route exact path="/app/edit" component={Edit}/>
		<Route exact path="/app/create" component={Create}/>
	</BrowserRouter>
);

export default Root;