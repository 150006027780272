var xhr = new XMLHttpRequest();
var url, apiToken;
if ( process.env.REACT_APP_NODE_ENV === 'production' ) {
    apiToken = "34caef0332595d13aa8148b02bea442f62bb1fa6";
    url = "https://watemplates.bimyou.es/api/v1/"; 
} else {
    apiToken = "ade6eb971138ed3287981e3b2799349ca03f53f2";
    url = "https://watemplatespre.bimyou.es/api/v1/";
}

export const LoginUser = (user, passwd) => new Promise((resolve, reject) => {
    var data = { user: user, pass: passwd };

    xhr.open('POST', `${url}login`, false);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", `Bearer ${apiToken}`);
    xhr.send(JSON.stringify(data));
    
    if ( xhr.status !== 200 ) {
        var msg = '';
        if ( xhr.status === 401 ) {
            msg = 'No active account found with the given credentials';
            return reject({message : msg});
        }
    } else {
        return resolve(JSON.parse(xhr.response));
    }
});